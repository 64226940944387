
import { Component, Vue, Watch, Ref } from "vue-property-decorator";
import * as DI from "@/utility/DependencyInjector";
import QuotationHeader from "@/components/Quotation/QuotationHeader.vue";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import HintModal from "@/components/Modal/Util/HintModal.vue";
import Loading from "@/components/Utility/Loading.vue";
import { Buffer } from "buffer";
import Swal from "sweetalert2";

@Component({
  components: {
    QuotationHeader,
    VuePdfEmbed,
    Loading,
    HintModal,
  },
})
export default class QuotationPdf extends Vue {
  // data
  isLoading = true;
  isDownloading = false;
  repairOrderId: any;
  quotationUrl = "";
  btnDisable = false;
  Swal = require("sweetalert2");
  orderFlag = false;
  firstQuote: boolean;
  quotationB64 = "";

  page: any = null;
  pageData?: any;
  pageCount = 0;
  renderCount = 0;
  fileName="";
  errorMsg=false;

  screenWidth = document.body.clientWidth;
  timer = false;

  //hook
  created() {
    this.isLoading = true;
    this.repairOrderId = this.$route.query.repairOrderId;
    const jsonString = JSON.stringify(this.$store.state.quotation);    
    const bufferObj = Buffer.from(jsonString, "utf8");
    
    this.quotationB64 = bufferObj.toString("base64");

    this.quotationPdf(this.quotationB64);
    this.censorOrder();
    this.firstQuotation();
  }

  mounted() {
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
  }

  async quotationPdf(quotationB64: string): Promise<void> {   
    this.isLoading = true;
    try {
      let res = DI.get("App").quotation.detailGeneratePdf(
        this.repairOrderId,
        quotationB64
      );

      res.then((d: any) => {
        const pdfUrl = URL.createObjectURL(d);
        this.quotationUrl = pdfUrl;
        this.isLoading = false;
      });
    } catch (e) {
      console.log("error", e);
    }
  }

  //計算頁碼
  handleDocumentRender(): void {
    this.isLoading = false;
    this.pageData = this.$refs.pdfRef;
    this.pageCount = this.pageData.pageCount;
    this.renderCount++;
  }

  clickReturn(): void {
    this.$router.push(
      `/Quotation/Standard?repairOrderId=${this.repairOrderId}`
    );
  }

  successAct(): void {
    localStorage.removeItem("allPanelData");
    localStorage.removeItem("expectElapsedDay");
    localStorage.removeItem("expectElapsedTime");
    this.$bvModal.hide("submit-modal");
    this.$router.push(
      `/Quotation/Info?repairOrderId=${this.repairOrderId}`
    );
    this.btnDisable = false;
  }

  //判斷是否是大訂單
  async censorOrder(): Promise<void> {
    const res = await DI.get("App").repairOrder.get(this.repairOrderId);
    const orderAdditionalFlag = res.orderAdditionalFlag;

    //判斷是否是大訂單->true:一般訂單/false:大筆金額
    if ((orderAdditionalFlag & 4) == 0) {
      this.orderFlag = true;
    }
  }

  //判斷是否是第一筆報價單
  async firstQuotation(): Promise<void> {
    try {
      const res = await DI.get("App").quotation.list({
        repairOrderId: this.repairOrderId,
      });
      let notFirst: boolean;

      if (res.data == undefined || res.data == "") {
        this.firstQuote = true;
      } else {
        notFirst = res.data.some((item: { status: number }) => {
          return item.status == 1;
        });
        if (notFirst) {
          this.firstQuote = false;
        } else {
          this.firstQuote = true;
        }
      }
    } catch (e) {
      this.firstQuote = true;
      console.log("error", e);
    }
  }  
 
  clickInputName():void{
    this.$bvModal.show('save-fileName')
    this.$bvModal.hide('submit-modal')
  }
  
    //命名檔名
  clickSubmit(fileName:any):void{   
    if(fileName){
      this.onSubmit()
      this.errorMsg=false
    }else{
     this.errorMsg=true 
    }  
  }

  async onSubmit(): Promise<void> {
    this.btnDisable = true;
    const quotation = JSON.parse(JSON.stringify(this.$store.state.quotation));
    const data = {
      quotationGroup: quotation.masterOfferedDetail,
      expectElapsedTime: quotation.expectElapsedTime,
      expectElapsedDay: quotation.expectElapsedDay,
      isDraft: false,
      fileName:this.fileName   
    };
    
    try {
      if (this.firstQuote) {
        try {
          await DI.get("App").quotation.create(this.repairOrderId, data);        
          Swal.fire({
            icon: "success",
            title: "已成功送出報價單",
            showConfirmButton: false,
            timer: 1500,
          });
          this.successAct();
        } catch (e) {
          console.log("error", e);
          Swal.fire({
            icon: "error",
            title: "無法送出報價單",
            showConfirmButton: false,
            timer: 1500,
          });
        } finally {
          this.btnDisable = false;
        }
      } else {
        try {
          await DI.get("App").quotation.append(
            this.repairOrderId,
            data,
            !this.orderFlag
          );         
          Swal.fire({
            icon: "success",
            title: "已成功送出",
            showConfirmButton: false,
            timer: 1500,
          });
          this.successAct();
        } catch (e) {
          console.log("error", e);
          Swal.fire({
            icon: "error",
            title: "無法送出報價單",
            showConfirmButton: false,
            timer: 1500,
          });
        } finally {
          this.btnDisable = false;
        }
      }
    } catch (e) {
      console.log("error", e);
    } finally {
      this.btnDisable = false;
    }
  }

  @Watch("screenWidth")
   getGcreenWidth(val: number):void {   
    if (!this.timer) {     
      this.screenWidth = val;
      this.timer = true;
      let that = this;
      setTimeout(() => {
        that.timer = false;
        this.quotationPdf(this.quotationB64);
      }, 2000);
    }
  }
}
